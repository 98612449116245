<po-page-default>
  <main>
    <div class="po-row">
      <h1>Token de recuperação de senha expirado ou inválido</h1>
    </div>
    <div class="po-row">
      <po-button routerLink="/" p-label="Voltar"></po-button>
    </div>
  </main>
</po-page-default>
