import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable, map, tap } from 'rxjs';
import { LoginService } from '../login/login.service';
import { MenuGet } from '../main/cadastros/perfil/model/menu';
import { MenuService } from '../main/cadastros/perfil/service/menu.service';

@Injectable({
  providedIn: 'root',
})
export class PerfilGuard {
  menus: MenuGet[] = [];
  idPerfil?: number;

  constructor(
    private menuService: MenuService,
    private loginService: LoginService,
    private router: Router
  ) {}

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const { id_perfil, id } = this.loginService.getLoggedUser();
    const allowedRoutes = [
      '/main/cadastros/prestadores/parametros-notificacoes-usuario',
      `/main/cadastros/prestadores/perfil/${id}`,
    ];
    const allowedRoute = allowedRoutes.some((route) => route === state.url);

    if (allowedRoute) {
      return true;
    }

    if (id_perfil !== this.idPerfil) {
      this.idPerfil = id_perfil;

      return this.menuService.getMenus({ idPerfil: id_perfil }).pipe(
        map((res) => {
          this.menus = res.items;

          return this.getAccessMenu(state.url);
        })
      );
    }

    if (this.menus.length) {
      return this.getAccessMenu(state.url);
    }

    return true;
  }

  getAccessMenu(url: string) {
    const [route, subRoute] = url.replace('/main/', '').split('/');

    const allowedMenu = this.menus!.some((menu) => {
      if (route === menu.path) {
        if (!menu.submenus.length) {
          return true;
        }

        return menu.submenus.some((submenu) => subRoute === submenu.path);
      }

      return false;
    });

    return allowedMenu || this.router.createUrlTree(['/main/']);
  }
}
