import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, firstValueFrom, map, tap } from 'rxjs';
import { LoginService } from '../login/login.service';

@Injectable({
  providedIn: 'root',
})
export class TokenRecuperacaoSenhaGuard  {
  constructor(private loginService: LoginService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const { token } = route.queryParams;

    if (!token) {
      this.router.navigate(['./']);
      return false;
    }

    return this.loginService.validarTokenRecuperacaoSenha({ token }).pipe(
      map((res) => {
        if (!res) {
          this.router.navigate(['/token-invalido']);
          return false;
        }
        return true;
      })
    );
  }
}
