import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MenuAPI, MenuGet } from '../model/menu';

const apiURL = `${environment.url}/menu`;

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  constructor(private http: HttpClient) {}

  public getMenus(data?: {
    idPerfil?: number;
    filter?: string;
  }): Observable<MenuAPI> {
    let params = new HttpParams();

    if (data) {
      Object.entries(data).forEach(
        ([key, value]) =>
          (params = value ? params.set(key, value.toString()) : params)
      );
    }

    const headers = new HttpHeaders().set(
      'authorization',
      'bearer ' + localStorage.getItem('token')
    );
    return this.http.get<MenuAPI>(apiURL, { params, headers });
  }

  public getMenu(id: number) {
    const headers = new HttpHeaders().set(
      'authorization',
      'bearer ' + localStorage.getItem('token')
    );
    return this.http.get<MenuGet>(`${apiURL}/${id}`, { headers });
  }

  public postMenu(perfilPost: MenuGet) {
    const headers = new HttpHeaders().set(
      'authorization',
      'bearer ' + localStorage.getItem('token')
    );
    return this.http.post<MenuAPI>(apiURL, perfilPost, { headers });
  }

  public editMenu(id: number, updatedMenu: MenuGet) {
    const headers = new HttpHeaders().set(
      'authorization',
      'bearer ' + localStorage.getItem('token')
    );
    return this.http.put<MenuAPI>(`${apiURL}/${id}`, updatedMenu, {
      headers,
    });
  }

  public deleteMenu(id: number) {
    const headers = new HttpHeaders().set(
      'authorization',
      'bearer ' + localStorage.getItem('token')
    );
    return this.http.delete<MenuAPI>(`${apiURL}/${id}`, { headers });
  }
}
