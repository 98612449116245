import { Component, ViewChild } from '@angular/core';
import { PrestadorService } from '../main/cadastros/prestador/services/prestador.service';
import {
  PoPageChangePassword,
  PoPageChangePasswordComponent,
} from '@po-ui/ng-templates';
import { LoginService } from '../login/login.service';
import { Buffer } from 'buffer';

@Component({
  selector: 'app-alterar-senha',
  templateUrl: './alterar-senha.component.html',
  styleUrls: ['./alterar-senha.component.css'],
})
export class AlterarSenhaComponent {
  @ViewChild(PoPageChangePasswordComponent)
  changePassword!: PoPageChangePasswordComponent;

  constructor(private loginService: LoginService) {}
  onSubmit(data: PoPageChangePassword) {
    this.loginService
      .alterarSenha(this.loginService.getLoggedUser().id, {
        currentPassword: Buffer.from(data.currentPassword!).toString('base64'),
        newPassword: Buffer.from(data.newPassword!).toString('base64'),
      })
      .subscribe({
        next: (res) => {
          this.changePassword.openConfirmation();
        },
      });
  }
}
