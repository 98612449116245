import { Component, OnInit, ViewChild } from '@angular/core';
import {
  PoPageChangePassword,
  PoPageChangePasswordComponent,
} from '@po-ui/ng-templates';
import { LoginService } from '../login/login.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-nova-senha',
  templateUrl: './nova-senha.component.html',
  styleUrls: ['./nova-senha.component.css'],
})
export class NovaSenhaComponent implements OnInit {
  @ViewChild(PoPageChangePasswordComponent)
  changePassword!: PoPageChangePasswordComponent;

  token?: string;

  constructor(
    private loginService: LoginService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.token = this.route.snapshot.queryParams['token'];
  }

  onSubmit(data: PoPageChangePassword) {
    this.loginService.novaSenha({ ...data, token: this.token! }).subscribe({
      next: () => {
        this.changePassword.openConfirmation();
      },
    });
  }
}
