import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AlterarSenhaComponent } from './alterar-senha/alterar-senha.component';
import { AuthGuard } from './guards/auth.guard';
import { TokenRecuperacaoSenhaGuard } from './guards/token-recuperacao-senha.guard';
import { PerfilGuard } from './guards/perfil.guard';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  {
    path: 'login',
    loadChildren: () =>
      import('./login/login.module').then((module) => module.LoginModule),
  },
  {
    path: 'main',
    loadChildren: () =>
      import('./main/main.module').then((module) => module.MainModule),
    canActivate: [AuthGuard],
    canActivateChild: [PerfilGuard],
  },
  {
    path: 'nova-senha',
    loadChildren: () =>
      import('./nova-senha/nova-senha.module').then(
        (module) => module.NovaSenhaModule
      ),
    canActivate: [TokenRecuperacaoSenhaGuard],
  },
  {
    path: 'token-invalido',
    loadChildren: () =>
      import('./token-invalido/token-invalido.module').then(
        (module) => module.TokenInvalidoModule
      ),
  },
  {
    path: 'alterar-senha',
    component: AlterarSenhaComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
