import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PoPageLogin } from '@po-ui/ng-templates';
import jwtDecode from 'jwt-decode';
import { Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PrestadorAPI } from '../main/cadastros/prestador/models/prestador';
import { LoggedUser } from './models/logged-user';
import { PoMenuMainService } from '../main/cadastros/perfil/service/po-menu-main.service';

const apiURL = `${environment.url}/auth`;

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(private http: HttpClient) {}

  autenticar(formData: PoPageLogin): Observable<any> {
    return this.http.post(apiURL, formData).pipe(
      tap((response: any) => {
        localStorage.setItem('token', response.token);
      })
    );
  }

  alterarSenha(
    id: number,
    body: { currentPassword?: string; newPassword?: string }
  ) {
    return this.http.put<PrestadorAPI>(`${apiURL}/alterar-senha/${id}`, body);
  }

  novaSenha(body: { token: string; newPassword?: string }) {
    return this.http.put<PrestadorAPI>(`${apiURL}/nova-senha`, body);
  }

  validarTokenRecuperacaoSenha(data: { token: string }) {
    return this.http.post(`${apiURL}/token-recuperacao-senha`, data);
  }

  getLoggedUser(): LoggedUser {
    return jwtDecode(localStorage.getItem('token')!);
  }

  isTokenExpired() {
    const token = localStorage.getItem('token');

    if (!token) {
      return true;
    }

    const decoded: any = jwtDecode(token);
    const date = new Date(0);

    date.setUTCSeconds(decoded.exp);

    return !(date.valueOf() > new Date().valueOf());
  }
}
